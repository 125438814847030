@font-face {
  font-family: roboto-bold;
  src: url(./assets/fonts/roboto/roboto.bold-webfont.woff);
}

@font-face {
  font-family: roboto-medium;
  src: url(./assets/fonts/roboto/roboto.medium-webfont.woff);
}

@font-face {
  font-family: roboto-regular;
  src: url(./assets/fonts/roboto/roboto.regular-webfont.woff);
}

h1,
h2,
h3,
h4 {
  font-family: roboto-bold !important;
}

h5,
h6 {
  font-family: roboto-medium !important;
}

p,
span,
div {
  font-family: roboto-regular !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p {
  padding: 0;
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #a7a6a8 !important;
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: inset 0 0 20px 20px transparent !important;
  caret-color: #a7a6a8 !important;
}

/* ************************** */
/* React Quill Custom Styling */
.dark > * .ql-toolbar.ql-snow,
.light > * .ql-toolbar.ql-snow {
  border-top-right-radius: 0.6rem !important;
  border-top-left-radius: 0.6rem !important;
  border: none !important;
}

.dark > * .ql-container.ql-snow,
.light > * .ql-container.ql-snow {
  border-bottom-right-radius: 0.6rem !important;
  border-bottom-left-radius: 0.6rem !important;
  border: none !important;
  min-height: 100px !important;
}

.dark > * .quill > .ql-container > .ql-editor.ql-blank::before,
.light > * .quill > .ql-container > .ql-editor.ql-blank::before {
  font-style: normal !important;
  font-size: 15px !important;
}

/* For Light Mode */
.light > * .ql-toolbar.ql-snow {
  background-color: #ebf1f7 !important;
}

.light > * .ql-container.ql-snow {
  background-color: #ebf1f7 !important;
}

.light > * .quill > .ql-container > .ql-editor.ql-blank::before {
  color: #95aac9 !important;
}

.quill > .ql-container {
  font-size: 0.9375rem !important;
}

/* For Dark Mode */
.dark > * .ql-toolbar.ql-snow {
  background-color: #1e2126 !important;
}

.dark > * .ql-container.ql-snow {
  background-color: #1e2126 !important;
}

.dark > * .quill > .ql-container > .ql-editor.ql-blank::before {
  color: #a7a6a8 !important;
}

.message-bar-quill > * .ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border: none !important;
}
.message-bar-quill > * .ql-container.ql-snow {
  max-height: 150px !important;
  overflow-y: auto !important;
}

.message-bar-quill > * .ql-tooltip,
.ql-tooltip.ql-editing {
  top: 0px !important;
  left: 15px !important;
}

.message-bar-quill > * .ql-editor::before {
  color: #a7a6a8 !important; /* Change this to your desired placeholder color */
}

.message-bar-quill-icons > i {
  color: #444 !important;
  cursor: pointer !important;
  font-size: 1.1rem !important;
}
.message-bar-quill-icons > i:hover {
  color: #1e66cc !important;
}

/* React Quill Custom Styling */
/* ************************** */
.flipped {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}

.display_none {
  display: none !important;
}

.display_block {
  display: contents !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

.round_avatar {
  border-radius: 100px !important;
  aspect-ratio: 1/1 !important;
}

.visualizer {
  width: 100% !important;
}

.mic_recording canvas {
  height: 100px !important;
}

.mic_recording wave {
  height: 100px !important;
}

.onLargerScreen,
.onSmallScreen {
  display: none !important;
}

.nav-logo-div {
  width: 75px !important;
  height: 75px !important;
  margin: auto !important;
}

@media screen and (max-width: 1199px) {
  .onSmallScreen {
    display: contents !important;
  }

  .nav-logo-div {
    width: 75px !important;
    height: 0px !important;
    margin: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .onLargerScreen {
    display: contents !important;
  }
}

[data-rmiz-modal-content] {
  background-color: gray;
  /* Chang background color of image zoom in chat */
}

.main {
  overflow-y: scroll !important;
  display: flex !important;
  flex-direction: column-reverse !important;
}

.main::-webkit-scrollbar {
  display: none !important;
}

/* Image Zoom Modal */
[data-rmiz-modal-content],
[data-rmiz-modal-overlay="visible"],
[data-rmiz-modal][open] {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

/* //////////////// */
.group-video-call-title {
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  order: 1 !important;
}

.group-video-call-buttons {
  order: 2 !important;
}

.group-video-call-timmer {
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
  order: 3 !important;
}

@media screen and (max-width: 767.5px) {
  .group-video-call-title {
    order: 1 !important;
  }

  .group-video-call-buttons {
    order: 3 !important;
    padding-top: 10px !important;
  }

  .group-video-call-timmer {
    order: 2 !important;
  }
}

.d_none {
  display: none !important;
}

.d_flex {
  display: flex !important;
}

audio::-webkit-media-controls-enclosure {
  background-color: rgb(235, 235, 235) !important;
}

.widgetCode_scrollbar {
  overflow-x: scroll !important;
}

/* Customize the scrollbar track */
.widgetCode_scrollbar::-webkit-scrollbar {
  height: 6px !important;
  /* Set the width of the scrollbar */
}

/* Customize the scrollbar thumb (the draggable part) */
.widgetCode_scrollbar::-webkit-scrollbar-thumb {
  background: white;
  /* Set the color of the thumb */
  border-radius: 5px;
  /* Round the edges of the thumb */
}

/* Customize the scrollbar track (the area behind the thumb) */
.widgetCode_scrollbar::-webkit-scrollbar-track {
  background: transparent;
  /* Set the color of the track */
}

.edit_message_input {
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  color: white !important;
}

.edit_message_input::-webkit-scrollbar {
  display: none !important;
}

.react-emoji-picker--wrapper {
  overflow: visible !important;
}

.emoji-mart-scroll::-webkit-scrollbar {
  display: none !important;
}

.fade_in {
  animation: fadeIn 0.75s;
  -webkit-animation: fadeIn 0.75s;
  -moz-animation: fadeIn 0.75s;
  -o-animation: fadeIn 0.75s;
  -ms-animation: fadeIn 0.75s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.avatar-mr-12 {
  margin-right: -12px !important;
}

.avatar-mr-15 {
  margin-right: -15px !important;
}

.avatar-mr-21 {
  margin-right: -21px !important;
}

.react-input-emoji--container {
  margin-left: -10px !important;
}

.read_more_btn {
  cursor: pointer;
  transition: 0.2s ease-in-out;
  color: forestgreen;
}

.read_more_btn:hover {
  opacity: 0.8;
  transition: 0.2s ease-in-out;
}

.react-input-emoji--button > * {
  width: 16px !important;
  height: 16px !important;
}

.bg-r {
  background-color: red !important;
}

.bg-g {
  background-color: green !important;
}

.bg-b {
  background-color: blue !important;
}

.call-frame-div {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media screen and (max-width: 575px) {
  .call-frame-div {
    display: grid !important;
  }
}

.group-call-frames-div {
  width: 100% !important;
  position: relative !important;
  padding: 10px;
  min-height: 50vh;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.group-call-frame {
  width: 50%;
  border-radius: 10px;
  height: 200px;
}

.calling-frames-div {
  width: 100% !important;
  display: grid !important;
  justify-content: center !important;
  position: relative !important;
  padding: 10px;
}

.call-large-frame {
  margin: auto !important;
  width: 70% !important;
  border-radius: 10px !important;
  max-height: max-content;
  height: 100%;
}

.call-small-frame {
  width: 20% !important;
  position: absolute !important;
  bottom: 0 !important;
  border-radius: 10px !important;
  margin: 10px;
}

.call-small-frame-webCam-none {
  background-color: white;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15% !important;
  position: absolute !important;
  bottom: 0 !important;
  border-radius: 10000px !important;
  margin: 10px;
  background: linear-gradient(45deg, blue, #17a2b8);
  color: white;
  font-size: 2rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.call-large-frame-webCam-none {
  height: 160px !important;
  background-color: white;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10000px !important;
  background: linear-gradient(45deg, blue, #17a2b8);
  color: white;
  font-size: 4rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
}

@media screen and (max-width: 991px) {
  .call-small-frame {
    width: 30% !important;
  }
}

@media screen and (max-width: 450px) {
  .call-small-frame {
    width: 35% !important;
  }
}

.active-share {
  animation: alert 1s infinite alternate;
  -webkit-animation: alert 1s infinite alternate;
  -moz-animation: alert 1s infinite alternate;
  -o-animation: alert 1s infinite alternate;
  -ms-animation: alert 1s infinite alternate;
}

.active-call {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  animation: alert 1s infinite alternate;
  -webkit-animation: alert 1s infinite alternate;
  -moz-animation: alert 1s infinite alternate;
  -o-animation: alert 1s infinite alternate;
  -ms-animation: alert 1s infinite alternate;
}

@keyframes alert {
  0% {
    background-color: rgba(0, 255, 0, 0.4);
  }

  100% {
    background-color: rgb(1, 206, 1);
  }
}

.call-button {
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.1); */
  background-color: #95aac9;
  color: white;
  transition: 0.2s ease-in-out;
}

.active-call-button {
  transition: 0.2s ease-in-out;
  background-color: white;
  color: black;
}

.active-call-button-light {
  transition: 0.2s ease-in-out;
  background-color: gray;
  color: white;
}

.call-button-danger {
  background: linear-gradient(rgb(237, 0, 0), rgb(178, 0, 0));
}

.call-frames-div {
  width: 100% !important;
  position: relative !important;
  padding: 10px;
  min-height: 50vh;
  display: flex;
}

.duo-video-call-local-div {
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000px;
  border-radius: 10px;
  margin: 10px;
}

.duo-video-call-local-div {
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000px;
  border-radius: 10px;
  margin: 10px;
}

.duo-video-call-local-light-div {
  background-color: rgba(211, 211, 211, 0.395);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000px;
  border-radius: 10px;
  margin: 10px;
}

.duo-video-call-local {
  bottom: 0;
  left: 0;
  position: absolute;
  border-radius: 10px;
}

.duo-video-call-local-name {
  bottom: 0;
  background: linear-gradient(45deg, blue, #17a2b8);
  aspect-ratio: 1/1;
  width: 120px;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  margin: 30px 0px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.duo-video-call-remote-light-div {
  background-color: rgba(211, 211, 211, 0.541);
  width: 70%;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.duo-video-call-remote-div {
  background-color: rgba(255, 255, 255, 0.1);
  width: 70%;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.duo-video-call-remote {
  width: 100%;
  border-radius: 10px;
}

.duo-video-call-remote-name {
  background: linear-gradient(45deg, #17a2b8, blue);
  width: 200px;
  aspect-ratio: 1/1;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  color: white;
  margin: 30px 0px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.display-name {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: black;
  color: white;
  padding: 2px 8px;
  /* transform: translate(5px, -5px); */
  margin: 5px;
  font-size: 12px;
  border-radius: 5px;
}

.display-name-remote {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: black;
  color: white;
  padding: 2px 8px;
  /* transform: translate(5px, -5px); */
  margin: 5px;
  font-size: 12px;
  border-radius: 5px;
}

.mic-icon-div-local,
.mic-icon-div-remote {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translate(10px, 10px); */
  margin: 10px;
}

.mic-icon-div-local {
  position: none;
}

.quick-responses::-webkit-scrollbar {
  width: 8px;
  /* Adjust width to match iOS scrollbar */
  height: 8px;
  /* Adjust height to match iOS scrollbar (for horizontal scrollbars) */
  background-color: transparent;
  /* Background of the scrollbar track */
}

/* Customize the scrollbar track */
.quick-responses::-webkit-scrollbar-track {
  background-color: transparent;
  /* Track is invisible */
}

/* Customize the scrollbar thumb */
.quick-responses::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 1);
  /* Color of the scrollbar thumb */
  border-radius: 4px;
  /* Rounded corners for the thumb */
}

/* Customize the scrollbar thumb when hovered */
.quick-responses::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
  /* Darker color when hovered */
}

/* Customize the scrollbar thumb when active (clicked) */
.quick-responses::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.7);
  /* Even darker color when active */
}

/* Customize the scrollbar corner (where horizontal and vertical scrollbars meet) */
.quick-responses::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Corner is invisible */
}

.quick-responses {
  background-color: #16191c;
  position: absolute;
  padding: 0.5rem;
  bottom: 0;
  transform: translate(0px, -80px);
  box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.5);
  border-radius: 0.6rem;
  max-height: 300px;
  overflow-y: scroll;
}

.quick-responses p {
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.quick-responses p:hover {
  background-color: #1e2126;
  transition: 0.3s ease-in-out;
}

.scrollbars > div {
  overflow-x: hidden !important;
}

.onHoverUnderline:hover {
  text-decoration: underline;
}

.onHoverScale {
  transform: scale(1);
  transition: 0.3s ease-in-out;
}
.onHoverScale:hover {
  transform: scale(1.04);
  transition: 0.3s ease-in-out;
}

.no-select {
  user-select: none;
}

.a-tag-color > * a {
  color: blue !important;
}

blockquote {
  margin: 16px 0;
  padding-left: 15px;
  border-left: 4px solid white;
}
